import React, { useContext, useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, IconButton, Menu, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import background from '../asset/reduit_beach2.jpg';
import {API} from '../asset/conf';
import { Context } from "../App";
import RotatDays from "./rotat-days";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

export default function Home() {
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [ports, setports] = useState([])
    const [tarifs, setTarifs] = useState([])
    const {isAR,setAR, isShopping,setShopping, rotations,setRotations, setPassagers} = useContext(Context)
    const [datea, setdatea] = useState('')
    const [dateb, setdateb] = useState('')
    const [from, setfrom] = useState('')
    const [to, setTo] = useState('')
    const [shopping_days, setShoppingDays] = useState([])
    const [cat_passengers, setpassengers] = useState([{label:'Adulte (+12 ans)', count:1}, {label:'Enfant B (5-12 ans)', count:0}, {label:'Enfant A (1-4 ans)', count:0}, {label:'Nourrisson (-1 ans)', count:0}])

    const [dispo_rotations, setRtns] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    async function handleSearch(event) {
        event.preventDefault()
        setLoad(true); 
        fetch(API.concat(`/reservation/rotations/search/${JSON.stringify({from, to})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }}).then(async response=>{
            if (response.status === 200) {
                const json = await response.json()
                Promise.all(json.map(rtn=> {return new Promise((resolve, reject) => {
                    rtn.from = ports.find(port=> port._id===rtn.from)
                    rtn.to = ports.find(port=> port._id===rtn.to)
                    resolve(rtn)
                })})).then(rtns=>{
                    setRtns(rtns); setLoad(false)
                })
            }else setLoad(false)
        })
    }

    function getShoppingDays(){
        setLoad(true)
        fetch(API.concat(`/reservation/rotations/shopping/${moment().format('yyyy-MM-DD')}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }}).then(async response=>{
            if (response.status===200) {
                const json = await response.json(); 
                setRtns(json)
                const dates = [...new Set(json.filter(rtn=> moment.utc(rtn.date_depart).isAfter(moment(),'d')).map(rtn=> moment.utc(rtn.date_depart).format('yyyy-MM-DD')))]
                setShoppingDays(dates)
                setLoad(false)
            }else setLoad(true)
        })
    }

    function get_total() {
        const grp_price = tarifs.find(tf=>tf.count&&tf.count===cat_passengers.reduce((tt,psg)=>tt+(!psg.label.toLowerCase().includes('nourrisson')?psg.count:0),0))?.prix?? 0
        const price = grp_price>0 ? grp_price : cat_passengers.reduce((tt, current)=>tt+current.count*(tarifs.find(tf=>tf.isA_R===isAR&&current.label?.toLowerCase().includes(tf.categorie))?.prix??0),0)
        return price.toFixed(2)
    }

    function handleSave() {
        setLoad(true)
        Promise.all(rotations.filter(r=>r!=='').map((rtn, index)=>{return new Promise((resolve, reject) => {
            const rotation = dispo_rotations.find(rt=> rt._id===rtn)
            if (rotation.date_depart&&!rotation.is_permanent) {
                resolve(rotation)
            } else {
                fetch(API.concat(`/reservation/rotations/permanents/${index>0?dateb:datea}`), {method:'POST', headers:{
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                    }, body: JSON.stringify(rotation)
                }).then(async response =>{
                    if (response.status === 200) {
                        const rtn = await response.json();
                        rtn.from = ports.find(port=> port._id===rtn.from)
                        rtn.to = ports.find(port=> port._id===rtn.to)
                        resolve(rtn)
                    }
                })
            }
        })})).then(rtns=>{
            setRotations(rtns)
            setPassagers(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)).fill(''))
            navigate('/passagers')
        })
    }

    function handleSaveShopping() {
        setLoad(true)
        const rtns = dispo_rotations.filter(rtn=> moment.utc(rtn.date_depart).format('yyyy-MM-DD')===datea)
        Promise.all(rtns.map(rtn=>{return new Promise((resolve, reject) => {
            rtn.from = ports.find(port=> port._id===rtn.from)
            rtn.to = ports.find(port=> port._id===rtn.to)
            resolve(rtn)
        })})).then(rtns=>{
            setRotations(rtns)
            setPassagers(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)).fill(''))
            navigate('/passagers')
        })
    }

    useEffect(()=>{
        function getPorts() {
            fetch(API.concat(`/reservation/ports`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                }
            }).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    setports(json)
                }
                setLoad(false)
            })
        }

        function getTarifs() {
            fetch(API.concat(`/reservation/tarifs`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
                }
            }).then(async response=>{
                if (response.status === 200) {
                    const json = await response.json()
                    setTarifs(json)
                }
            })
        }

        getPorts(); getTarifs()
    }, [])

    return(
        <Box display='flex' flex={1} flexDirection='column'>
            <Box display='flex' alignItems='center' justifyContent='center' sx={{backgroundImage: `url(${background})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', height:'100%', minHeight:'500px', paddingInline:'30px', paddingBlock:'40px'}}>
                <Box sx={{borderRadius:2, overflow:'hidden', minWidth:'400px', width:'42%', backgroundColor:"white"}}>
                    <Typography variant="h3" sx={{backgroundColor:'rose.main'}} color="white" style={{textAlign:'center', paddingBlock:'15px' }}>Réservez votre billet</Typography>
                    <RadioGroup value={isShopping? "shopping" : isAR.toString()} row sx={{marginX:'20px', marginY:'10px'}}
                    onChange={(e)=> setRotations(old=>{
                        if (e.target.value==='shopping') {
                            setShopping(true); setAR(false)
                            getShoppingDays()
                        } else {
                            setAR(e.target.value==='true'? true: false)
                            setShopping(false)  
                            setRtns([])                         
                        }
                        return [old[0]??'']
                    })}>
                        <FormControlLabel value={"true"} control={<Radio />} label="Aller/Retour" sx={{marginRight:'50px'}}/>
                        <FormControlLabel value={"false"} control={<Radio />} label="Aller simple" />
                        <FormControlLabel value={"shopping"} control={<Radio />} label="Journée shopping" />
                    </RadioGroup>
                    {isShopping? <Stack component='form' onSubmit={handleSaveShopping} direction='column' alignItems='center' spacing={2} marginX='20px' marginBottom='20px'>
                        <TextField required select fullWidth label='Date' value={datea} onChange={e=> setdatea(e.target.value)}>
                            {shopping_days.map((day, index)=>
                            <MenuItem key={index} value={day}>{moment.utc(day).format('dddd D MMMM YYYY')}</MenuItem>)}
                            {shopping_days.length<1&&<Typography align="center">Aucune journée shopping programmée à ce jour.<br/>Veuillez ré-essayer plus tard</Typography>}
                        </TextField>
                        <Button fullWidth variant="outlined"
                        style={{color:'black', textTransform:'none', whiteSpace:'pre-line'}}
                        aria-controls={open ? 'Passagers' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        >
                            <Typography><span style={{fontWeight:'500'}}>Passagers</span><br/>
                            {cat_passengers.reduce((all, current)=>all+current.count,0)} passager(s)
                            </Typography>
                        </Button>
                        <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'Passagers',
                        }}
                        >
                            {cat_passengers.map(cat_passenger=>
                            <MenuItem key={cat_passenger.label}>
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label){
                                    p.count+=1
                                }return p }))}><Add/></IconButton>
                                {cat_passenger.count}
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label&&p.count>0){
                                    p.count-=1
                                }return p }))}><Remove/></IconButton>
                                <span style={{textTransform:'capitalize'}}>{cat_passenger.label}</span>
                            </MenuItem>)}
                        </Menu>
                        <Button variant="contained"
                        type='submit'
                        disabled={datea===''||cat_passengers.reduce((all, current)=>all+current.count,0)<=0}
                        sx={{ width:'90%', borderRadius:1}}>
                            réserver
                        </Button>
                    </Stack>
                    :<Stack component='form' onSubmit={handleSearch} direction='column' alignItems='center' spacing={2} marginX='20px' marginBottom='20px'>
                        <Stack direction='row' spacing={2} width='100%'>
                            <TextField required type="date" value={datea} onChange={(e)=> {setdatea(e.target.value); if (dateb!==''&&new Date(e.target.value)>new Date(dateb)){setdateb('')}}} 
                            inputProps={{min: new Date().toISOString().split("T")[0]}}
                            fullWidth sx={{minWidth:'100px'}} label='Aller' InputLabelProps={{shrink: true}}/>
                            <TextField type="date" value={dateb} onChange={(e)=> {setdateb(e.target.value); if (datea!==''&&new Date(e.target.value)<new Date(datea)){setdatea('')}}} 
                            inputProps={{min: (datea!=='' ?new Date(datea) :  new Date()).toISOString().split("T")[0]}} disabled={!isAR}
                            fullWidth sx={{minWidth:'100px'}} label='Retour' InputLabelProps={{shrink: true}}/>
                        </Stack>
                        <TextField required select fullWidth label='Départ' value={from} onChange={e=>setfrom(e.target.value)}>
                            {ports.map(port=><MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize'}}>{port.pays+' - '+port.nom}</MenuItem>)}
                        </TextField>
                        <TextField required select fullWidth label='Arrivée' value={to} onChange={e=> setTo(e.target.value)}>
                            {ports.map(port=><MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize'}}>{port.pays+' - '+port.nom}</MenuItem>)}
                        </TextField>
                        <Button fullWidth variant="outlined"
                        style={{color:'black', textTransform:'none', whiteSpace:'pre-line'}}
                        aria-controls={open ? 'Passagers' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        >
                            <Typography><span style={{fontWeight:'500'}}>Passagers</span><br/>
                            {cat_passengers.reduce((all, current)=>all+current.count,0)} passager(s)
                            </Typography>
                        </Button>
                        <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'Passagers',
                        }}
                        >
                            {cat_passengers.map(cat_passenger=>
                            <MenuItem key={cat_passenger.label}>
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label){
                                    p.count+=1
                                }return p }))}><Add/></IconButton>
                                {cat_passenger.count}
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label&&p.count>0){
                                    p.count-=1
                                }return p }))}><Remove/></IconButton>
                                <span style={{textTransform:'capitalize'}}>{cat_passenger.label}</span>
                            </MenuItem>)}
                        </Menu>
                        <Button variant="contained"
                        type='submit'
                        disabled={(isAR&&dateb==='')||cat_passengers.reduce((all, current)=>all+current.count,0)<=0}
                        sx={{ width:'90%', borderRadius:1}}>
                            rechercher
                        </Button>
                    </Stack>}
                </Box>
            </Box>

            {!isShopping&&<>{dispo_rotations.length>0&&<Box>
                <Typography variant="h3" sx={{margin:'40px 30px' }}>Traversée Aller</Typography>
                <RotatDays date={datea} psg_count={cat_passengers.filter(cat=>cat.label!=='Nourrisson (-1 ans)').reduce((tt, psg)=>tt+psg.count,0)} setdate={setdatea} setrotation={(rotat)=>setRotations(old=>[rotat, old[1]??''])} price={get_total()} rotations={dispo_rotations.filter(rtn=>rtn.from._id===from)}/>
            </Box>}
            {dispo_rotations.length>0&&isAR&&<Box>
                <Typography variant="h3" sx={{margin:'40px 30px' }}>Traversée Retour</Typography>
                <RotatDays date={dateb} psg_count={cat_passengers.filter(cat=>cat.label!=='Nourrisson (-1 ans)').reduce((tt, psg)=>tt+psg.count,0)} setdate={setdateb} setrotation={(rotat)=>setRotations(old=>[old[0]??'', rotat])} price={get_total()} rotations={dispo_rotations.filter(rtn=>rtn.from._id===to)}/>
            </Box>}
            
            {dispo_rotations.length>0&&<Button onClick={handleSave} variant="contained" style={{alignSelf:'end', margin:'30px'}} disabled={rotations.filter(rtn=>rtn!=='').length<(isAR?2:1)}>Continuer</Button>}</>}
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}