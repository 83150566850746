import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import ClientForm from './client-form';
import { Context } from "../App";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
// import { API } from "../asset/conf";

export default function PassagersInfo() {
    const navigate = useNavigate()
    // const [load, setLoad] = useState(false)
    const [no_pmr, setPmr] = useState([])
    const [expanded, setExpanded] = useState(0);
    const [have_mineur, setMineur] = useState(false)
    const [open ,setOpen] = useState(false)
    const [action , setAction] = useState('')
    const {rotations, passagers,setPassagers, mail,setmail} = useContext(Context)

    // function submit() {
    //     fetch(API.concat('/reservation/passagers'), {method:'POST', headers:{
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json'
    //         }, body: JSON.stringify(passagers)
    //     }).then(async response=>{
    //         if (response.status === 200) {
    //             const psg = await response.json()
    //             setPassagers(psg)
    //             navigate('/recapitulatif')
    //         }setLoad(false)
    //     })
    // }

    function handleSubmit() {
        const pmr = passagers.reduce((tt, psg)=>tt+(psg.isPmr?1:0),0)
        const no_pmr = rotations.filter(rtn=>rtn.dispo_pmr<pmr)
        if(no_pmr.length>0){
            setPmr(no_pmr)
            setOpen(true); setAction('pmr')
        }else if(have_mineur){
            setOpen(true); setAction('mineur')
        }
        else navigate('/recapitulatif')
    }

    useEffect(()=>{
        if (rotations.length<1||passagers.length<1) {
            navigate('/')
        }
    },[rotations, passagers])

    return(
        <Box sx={{display:'flex', flex:1, flexDirection:'column', marginBottom:'30px'}}>
            <Box display='flex' flexDirection='column' sx={{ minWidth:'700px', width:'60%', alignSelf:'center'}}>
                <Typography variant="h3" sx={{ margin:'30px' }}>Informations Passager(s)</Typography>
                {passagers.map((passager, index) => 
                <Accordion key={index} expanded={expanded === index} onChange={(event, isExpanded)=> setExpanded(isExpanded ? index : false)}>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Passager {index+1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ClientForm index={index} passager={passager} setmineur={setMineur} add={psg=> setPassagers(old=>{const np=[...old]; np[index]=psg; return np})} close={()=>setExpanded(index<passagers.length-1 ? index+1 : false)} />                                
                    </AccordionDetails>
                </Accordion>)}
            </Box>

            <Stack direction='column' spacing={2} sx={{ minWidth:'700px', width:'60%', alignSelf:'center', marginTop:3}}>
                <Typography variant="subtitle1" marginX={3}>Informations de contact et de facturation</Typography>
                <Stack direction='row' spacing={2} alignItems='center' marginX={3}>
                    <TextField type="name" label="Nom complet" required fullWidth/>
                    <TextField type="email" label="Adresse e-mail de contact" placeholder="exemple@abc123.com" required fullWidth
                    value={mail} onChange={e=>setmail(e.target.value)} sx={{minWidth:'250px', margin:'20px', alignSelf:'center'}}/>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <TextField type="address" label="Adresse postale" fullWidth/>
                    <TextField label="Complément d'adresse" fullWidth/>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <TextField type="zip-code" label="Code postale" required sx={{width:'15%', minWidth:'120px'}}/>
                    <TextField type="city" label="Ville" required fullWidth/>
                    <TextField label="Pays" required fullWidth/>
                </Stack>
            </Stack >
            
            <Button
            onClick={handleSubmit}
            variant="contained"
            style={{alignSelf:'end', marginRight:'20px'}}
            color="primary"
            disabled={passagers.includes('')||mail.length<6}
            >
                Continuer
            </Button>

            <Dialog open={open}>
                {action==='pmr'&&<><DialogTitle>Il ne reste plus assez de place PMR sur {no_pmr.length>1?'les traversées':'la traversée'} {no_pmr.map(rtn=> rtn.from.nom+' -> '+rtn.to.nom).join(' et ')}</DialogTitle>
                <DialogContent>Veuillez choisir une autre traversée</DialogContent>
                <DialogActions>
                    <Button variant="clear" onClick={()=>{setPmr([]); setOpen(false)}}>Ok</Button>
                </DialogActions></>}
                {action==='mineur'&&<Box display='flex' flexDirection='column' padding='20px'>
                    <Typography variant="h3" align="center" marginBottom='20px'>Vous voyager avec un mineur</Typography>
                    <Typography marginBottom='10px'>Lorsqu'un enfant est mineur et voyage, il est nécessaire que l'accompagnant porte le même nom ou fournisse le livret de famille pour prouver les liens parentaux (mère ou père). En cas contraire, il doit présenter un jugement. Si l'enfant voyage sans ses parents, une autorisation de sortie du territoire est requise, accompagnée d'une photocopie de la pièce d'identité des parents.</Typography>
                    <Typography marginBottom='10px'>Chaque voyageur français mineur doit être muni d'un document d'identité tel qu'un passeport ou une carte nationale d'identité, en fonction de la destination. Ce document doit impérativement être établi à son propre nom. Il est à noter qu'un enfant ne peut être mentionné sur le passeport d'un de ses parents.</Typography>
                    <Typography marginBottom='10px'>Il est important de noter que le livret de famille n'est pas considéré comme une pièce d'identité valable. Par conséquent, il ne permet pas au voyageur mineur d'effectuer des déplacements, même sur les trajets intérieurs.</Typography>
                    <Typography marginBottom='20px'>Il convient de signaler que si vous voyagez avec votre enfant et qu'il ne partage pas votre nom, veuillez vous munir du livret de famille ou d'un extrait d'acte de naissance pour prouver votre lien de parenté.</Typography>
                    <Typography variant="subtitle1" align="center" marginBottom='15px'>Autorisation de sortie de territoire pour les mineurs de moins de 18 ans</Typography>
                    <Typography marginBottom='10px' variant="subtitle2">Les mineurs de 16 ou 17 ans ont la possibilité de voyager seuls, tandis que les mineurs de moins de 16 ans doivent être accompagnés d'un adulte lors de leurs déplacements.</Typography>
                    <Typography marginBottom='10px'>Tout mineur, qu'il soit français ou étranger résidant en France, et voyageant hors du pays sans être accompagné par un titulaire de l'autorité parentale, doit se conformer à certaines exigences.</Typography>
                    <Typography marginBottom='10px'>L'enfant doit être muni de l'un des éléments suivants : sa propre carte d'identité ou son passeport (accompagné d'un visa si requis), l'autorisation de sortie du territoire dûment signée par l'un des détenteurs de l'autorité parentale, ainsi que la copie d'un document d'identité valide de la personne ayant apposé sa signature sur l'autorisation de sortie du territoire.</Typography>
                    <Button variant="contained" sx={{alignSelf:'center'}} onClick={()=>{navigate('/recapitulatif')}}>d'accord</Button>
                </Box>}
            </Dialog>
            {/* <Backdrop open={load}><CircularProgress/></Backdrop> */}
        </Box>
    )
}