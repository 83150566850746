import React from "react";
import { Typography, Link } from "@mui/material"

export default function Footer() {
    return(
        <div style={{backgroundColor:'#3F88C5', padding: 15, display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Typography fontWeight='bold' fontSize={12} fontFamily={'Inter'} color='#fff'>&copy; 2024 Transports Express Caraïbes  –</Typography>
            <Link href="#" underline="none" color='#fff' fontSize={12} style={{marginLeft:4}}>Mentions légales – Confidentialité – Cookies</Link>
        </div>
    )
}